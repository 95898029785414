
import { Vue, Options } from 'vue-class-component';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import debounce from 'lodash-es/debounce';
import { IModalAction } from '@/lib';
import {
  SearchInput,
  DataTable,
  BasePopover,
  SpecialityButton,
  PopoverButton,
  BasePagination,
  BaseTooltip,
  ActionModal
} from '@/lib/components';
import { PaginatedResponse, User } from '@/models';
import { AdminUserService, DomainUserService, UsersService } from '@/services/api';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';

import { useNotificationStore } from '@/stores/notification.store';
@Options({
  components: {
    ActionModal,
    BaseTooltip,
    BasePagination,
    PopoverButton,
    SpecialityButton,
    BasePopover,
    DataTable,
    SearchInput
  }
})
export default class UsersPage extends Vue {
  loading = true;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  rows: User[] = [];
  perPage = 0;
  total = 0;
  search = '';
  adminUserService = new AdminUserService();

  usersService = new UsersService();
  modalUserId: string | null = null;
  request: CancelTokenSource | null = null;

  removeUserId: string | null = null;

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  get columns() {
    return [
      { name: 'activated_at', label: this.$t('platform.common.status'), sortable: true },
      { name: 'family_name', label: this.$t('platform.user.details'), sortable: true },
      { name: 'org-units', label: this.$t('platform.org-unit.ous') },
      { name: 'actions', label: '', align: 'right' }
    ];
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'family_name';
  }

  getActionableDomainId(actionableDomains: Array<string>) {
    const domainIds = actionableDomains.filter((id: string) =>
      this.sessionStore.currentUser.actionable_domain_ids.includes(id)
    );
    return domainIds.length ? domainIds[0] : null;
  }

  getDomainUsersService(actionableDomains: Array<string>) {
    const domainId = this.getActionableDomainId(actionableDomains);
    return domainId ? new DomainUserService(domainId) : null;
  }

  getMfaModalActions(userId: string): Array<IModalAction> {
    return [
      {
        label: this.$t('platform.profile.confirm-reset') as string,
        color: 'danger',
        onClick: () => this.resetMFA(userId)
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.modalUserId = null)
      }
    ];
  }

  get removeUserModalActions(): Array<IModalAction> {
    return [
      {
        label: this.$t('platform.domain-admin.users.remove-from-orgs.modal.remove-user-label') as string,
        color: 'primary',
        onClick: () => this.removeUserFromAllOrgs(this.removeUserId as string)
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.removeUserId = null)
      }
    ];
  }

  async mounted() {
    this.search = String(this.$route.query.search || '');
    await this.fetchUsers();

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchUsers();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  created() {
    this.progressStore.startProgress();
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async removeUserFromAllOrgs(userId: string) {
    try {
      await this.adminUserService.delete(userId);
      this.notificationStore.snackbar = {
        label: this.$t('platform.domain-admin.users.remove-from-orgs.success')
      };
      this.removeUserId = null;
      await this.fetchUsers();
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.domain-admin.users.remove-from-orgs.error')
      });
    }
  }

  async requestPasswordReset(user: User) {
    try {
      const domainService = user.actionable_domain_ids ? this.getDomainUsersService(user.actionable_domain_ids) : null;
      if (domainService) {
        await domainService.resetPassword(user.id);
        this.notificationStore.addSuccessNotification({
          title: this.$t('platform.user.reset-password-success')
        });
      }
    } catch (err) {
      console.warn(err);
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.user.reset-password-error')
      });
    }
  }

  async resetMFA(userId: string) {
    try {
      await this.adminUserService.resetMFA(userId);
      this.modalUserId = null;
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.profile.reset-mfa-success')
      });
    } catch (err) {
      console.warn(err);
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.profile.reset-mfa-error')
      });
    }
  }

  async resendInvite(user: User) {
    try {
      const domainService = user.actionable_domain_ids ? this.getDomainUsersService(user.actionable_domain_ids) : null;
      if (domainService) {
        await domainService.resendInvite(user.id);
        this.notificationStore.addSuccessNotification({
          title: this.$t('platform.user.invite-resent')
        });
      }
    } catch (err) {
      console.warn(err);
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.user.invite-resend-error')
      });
    }
  }

  async fetchUsers() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        page: this.page,
        sort: this.sort,
        append: 'actionable_domain_ids',
        ...(this.search ? { 'filter[search]': this.search } : {})
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.usersService.index(requestConfig)) as PaginatedResponse<User[]>;
      this.rows = response.data;
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.user.fetch-error')
        });
      }
    } finally {
      this.loading = false;
      this.request = null;
    }
  }
}
