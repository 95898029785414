import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 mb-6 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex py-4 items-center" }
const _hoisted_6 = { class: "mt-8" }
const _hoisted_7 = { class: "font-medium text-gray-900" }
const _hoisted_8 = { class: "font-medium text-sm text-gray-500" }
const _hoisted_9 = {
  key: 0,
  class: "font-medium text-sm text-gray-400"
}
const _hoisted_10 = { class: "flex flex-row items-center" }
const _hoisted_11 = { class: "flex flex-col p-2" }
const _hoisted_12 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.search.length ? _ctx.$t('platform.common.search-results') : _ctx.$t('platform.user.all')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SearchInput, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            class: "mr-4 w-40 text-sm",
            icon: "search",
            onChange: _ctx.updateSearchDebounced
          }, null, 8, ["modelValue", "onChange"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_DataTable, {
          loading: _ctx.loading,
          columns: _ctx.columns,
          rows: _ctx.rows,
          sort: _ctx.sort,
          class: "w-full mt-6",
          onSort: _ctx.updateSort
        }, {
          "cell.activated_at": _withCtx(({ row }) => [
            _createVNode(_component_MkBadge, {
              color: row.activated_at ? 'success' : 'primary',
              stroked: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.activated_at ? _ctx.$t('platform.user.active') : _ctx.$t('platform.user.pending')), 1)
              ]),
              _: 2
            }, 1032, ["color"])
          ]),
          "cell.family_name": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_7, _toDisplayString(row.family_name) + ", " + _toDisplayString(row.given_name), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(row.email), 1)
          ]),
          "cell.org-units": _withCtx(({ row }) => [
            (row.organisations.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createTextVNode(_toDisplayString(row.organisations[0].name) + " ", 1),
                    _createVNode(_component_BaseTooltip, { class: "relative" }, {
                      target: _withCtx(() => [
                        (row.organisations.length > 1)
                          ? (_openBlock(), _createBlock(_component_MkBadge, {
                              key: 0,
                              color: "gray",
                              class: "ml-3"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('platform.user.more', [row.organisations.length - 1])), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.organisations, (org, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: org.id,
                              class: _normalizeClass([{ hidden: !index, 'mt-2': index > 1 }, "text-white"])
                            }, _toDisplayString(org.name), 3))
                          }), 128))
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          "cell.actions": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_BasePopover, { position: "left" }, {
                button: _withCtx(() => [
                  _createVNode(_component_SpecialityButton)
                ]),
                menu: _withCtx(() => [
                  (!row.activated_at && row.actionable_domain_ids.length)
                    ? (_openBlock(), _createBlock(_component_PopoverButton, {
                        key: 0,
                        onClick: ($event: any) => (_ctx.resendInvite(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.user.resend-invite')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (row.requires_mfa)
                    ? (_openBlock(), _createBlock(_component_PopoverButton, {
                        key: 1,
                        onClick: ($event: any) => (_ctx.modalUserId = row.id)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.profile.reset-mfa')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (row.actionable_domain_ids.length)
                    ? (_openBlock(), _createBlock(_component_PopoverButton, {
                        key: 2,
                        onClick: ($event: any) => (_ctx.requestPasswordReset(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.profile.reset-password')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_PopoverButton, {
                    onClick: ($event: any) => (_ctx.removeUserId = row.id)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.domain-admin.users.remove-from-orgs.label')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _: 1
        }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
        (_ctx.total && _ctx.total > _ctx.perPage)
          ? (_openBlock(), _createBlock(_component_BasePagination, {
              key: 0,
              "sm-pages-limit": 8,
              "model-value": _ctx.page,
              "items-name": _ctx.$t('platform.settings.users').toLowerCase(),
              "page-size": _ctx.perPage,
              length: _ctx.total,
              "onUpdate:modelValue": _ctx.changePage
            }, null, 8, ["model-value", "items-name", "page-size", "length", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.modalUserId)
      ? (_openBlock(), _createBlock(_component_ActionModal, {
          key: 0,
          title: _ctx.$t('platform.profile.reset-mfa'),
          body: _ctx.$t('platform.profile.reset-mfa-action'),
          actions: _ctx.getMfaModalActions(_ctx.modalUserId),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalUserId = null))
        }, null, 8, ["title", "body", "actions"]))
      : _createCommentVNode("", true),
    (_ctx.removeUserId)
      ? (_openBlock(), _createBlock(_component_ActionModal, {
          key: 1,
          icon: "bell",
          "icon-color": "white",
          "icon-bg-color": "alert-400",
          "show-close": false,
          title: _ctx.$t('platform.common.are-you-sure'),
          body: _ctx.$t('platform.domain-admin.users.remove-from-orgs.modal.body'),
          actions: _ctx.removeUserModalActions,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeUserId = null))
        }, null, 8, ["title", "body", "actions"]))
      : _createCommentVNode("", true)
  ]))
}